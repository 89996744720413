import { MODAL_ROUTES, ROUTES } from "routing/routes";

type RouteConfig = {
  path: string;
};

/*
  routesConfig is needed to ensure getting the parameterized transaction names out of routes in Sentry performance monitoring dashboard.
  To get parameterized transaction names (for example, /class/:classId/user/:userid instead of /class/123/user/456), 
  we must explicitly set the routes we want parameterized. 
  That's because there is no static route config that the SDK can use in React Router v4/v5.

  Note: once we migrate to React Router v6 , or Sentry v8 we probably no longer need this file.
*/

export const routesConfig: RouteConfig[] = [
  { path: "/parent/monitoring/overview" },
  { path: "/parent/child/:childId/overview" },
  { path: "/parent/child/:userId/assignments" },
  { path: "/parent/child/:userId/assignment/:assignmentId" },
  { path: "/parent/child/:userId/report" },
  { path: "/parent/tutoring" },
  { path: "/parent/what-is-premium" },
  { path: "/dashboard/class/:classId/assignments/assignment/:assignmentId" },
  { path: "/dashboard/assignments/todo" },
  { path: "/dashboard/class/:classId/assignments/missed" },
  { path: "/dashboard/class/:classId/assignments/todo" },
  { path: "/dashboard/class/:classId/assignments/done" },
  { path: "/dashboard/join-class/:classId" },
  { path: "/dashboard/class/:classId" },
  { path: "/dashboard/courses/add" },
  { path: "/dashboard" },
  { path: "/dashboard/what-is-premium" },
  {
    path: "/classroom/course/:courseId/section/:sectionId/session/end-session"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId/session/start"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId/exam-questions/session"
  },
  {
    path: "/classroom/course/:courseId/section/:sectionId"
  },
  { path: "/classroom/course/:courseId/assignments/upcoming" },
  { path: "/classroom/course/:courseId/assignments/past" },
  { path: "/classroom/course/:courseId/exam-questions/session" },
  { path: "/classroom/course/:courseId" },
  { path: ROUTES.TEACHER.CLASS.OVERVIEW.path },
  { path: ROUTES.TEACHER.CLASS.COURSES.path },
  { path: ROUTES.TEACHER.CLASS.STUDENTS.path },
  { path: ROUTES.TEACHER.CLASS.ADD_COURSES.path },
  { path: ROUTES.TEACHER.CLASS.INVITE_STUDENTS.path },
  {
    path: ROUTES.TEACHER.CLASS.ASSIGNMENTS.ASSIGNMENT.path
  },
  { path: ROUTES.TEACHER.CLASS.ASSIGNMENTS.path },
  { path: ROUTES.TEACHER.CLASS.GRADES.path },
  { path: ROUTES.TEACHER.CLASS.SETTINGS.path },
  { path: ROUTES.TEACHER.CLASS.path },
  { path: MODAL_ROUTES.INVITE_PARENT_INFO.path },
  { path: MODAL_ROUTES.INVITE_CLASSES_PARENTS.path },
  { path: MODAL_ROUTES.MANAGE_TEACHERS.path },
  { path: MODAL_ROUTES.MANAGE_STUDENT.path },
  {
    path: MODAL_ROUTES.SHARE_ASSIGNMENT.path
  },
  { path: MODAL_ROUTES.INSIGHTS_SECTIONS_SELECTOR.path },
  { path: MODAL_ROUTES.ARCHIVE_CLASSES.path },
  { path: MODAL_ROUTES.LEAVE_CLASS.path },
  { path: MODAL_ROUTES.DELETE_CLASS.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.CLASS.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.TYPE.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.TOPICS.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.EXAM_QUESTIONS.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.QUIZZES.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.VOCABULARY.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.ASSIGNMENT.CLASSES.path },
  { path: ROUTES.TEACHER.ASSIGNMENTS.ASSIGNMENT.STUDENTS.path },
  { path: ROUTES.TEACHER.QUIZZES.path },
  { path: ROUTES.TEACHER.QUIZZES.SCHOOL.path },
  { path: ROUTES.TEACHER.QUIZZES.QUIZ.path },
  { path: ROUTES.TEACHER.QUIZZES.CREATE.DETAILS.path },
  { path: ROUTES.TEACHER.QUIZZES.CREATE.INPUT.path },
  { path: ROUTES.TEACHER.QUIZZES.CREATE.GENERATING.path }
];
