import { TransactionContext } from "@sentry/types";
import { matchPath } from "react-router-dom";

import { ROUTES } from "routing/routes";

const CREATE_ASSIGNMENT_SAMPLE_RATE = 0.06;

const topLandingRoutesSampleConfig = [
  {
    route: "/dashboard/class/:classId/assignments/assignment/:assignmentId",
    sampleRate: 0.02
  },
  {
    route: "/classroom/course/:courseId",
    sampleRate: 0.005
  },
  {
    route: "/classroom/course/:courseId/section/:sectionId/section-overview",
    sampleRate: 0.005
  },
  {
    route: "/dashboard",
    sampleRate: 0.02
  },
  {
    route: "/dashboard/courses/add",
    sampleRate: 0.05
  },
  {
    route: "/dashboard/join-class/:classId",
    sampleRate: 0.05
  },
  {
    route: "/parent/monitoring/overview",
    sampleRate: 0.01
  },
  {
    route: "/parent/child/:childId/overview",
    sampleRate: 0.01
  },
  {
    route: "/parent/child/:userId/assignment/:assignmentId",
    sampleRate: 0.01
  },
  {
    route: "/parent/child/:userId/report",
    sampleRate: 0.01
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.CLASS.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.TYPE.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.TOPICS.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.EXAM_QUESTIONS.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.QUIZZES.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  },
  {
    path: ROUTES.TEACHER.ASSIGNMENTS.CREATE.VOCABULARY.path,
    sampleRate: CREATE_ASSIGNMENT_SAMPLE_RATE
  }
];

export function shouldSampleLandingPage(
  context: TransactionContext,
  random: number
) {
  const currentLocationPath = context.name;

  const shouldSample = topLandingRoutesSampleConfig.some(
    ({ route: topLandingPageRoute, sampleRate }) => {
      const toSample = matchPath(currentLocationPath, {
        path: topLandingPageRoute,
        exact: true
      });

      return toSample && random < sampleRate;
    }
  );

  return shouldSample;
}
