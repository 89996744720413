import { LinkProps, Location, To } from "react-router-dom-v5-compat";

import {
  ContentType,
  COURSE_SUBSCRIPTION_TIERS
} from "@seneca/course-service-types";
import { TUTORING_LEAD_SOURCES } from "@seneca/tutoring-service-consts";

import { PartialLocation } from "common/types/routing";

import { AnyAnswerI } from "services/shortAnswerService/answer-types/AnyAnswer";
import { AnyQuestionI } from "services/shortAnswerService/question-types/Question";

import { CourseAddedLocation } from "seneca-common/features/class/analytics/events";
import {
  parser,
  StateParamType,
  type,
  Validator
} from "seneca-common/utils/routing/react-router-typesafe-routes/common";

import { MiniMockSessionData } from "features/classroom/pages/exam-questions/pages/mini-mock-pages/analytics/useMiniMockAnalytics";
import { TutoringFormModalStep } from "features/parents/features/tutoring-form-modal/consts";
import { ParentPlatformPreviewSource } from "features/teachers/features/analytics/class-management";
import { CreateAssignmentSource } from "features/teachers/features/assignment-journey/consts";
import {
  SelectedCourseContent,
  SelectedQuiz
} from "features/teachers/features/assignment-journey/types";
import { ParentAutoInvitesFlow } from "features/teachers/features/parent-auto-invites/hooks/useNavigateParentAutoInvitesModal";
import { AssignmentsTableMode } from "features/teachers/pages/assignment/components/AssignmentsInsightsTable/AssignmentsInsightsTable";
import { ShareSource } from "features/teachers/pages/quizzes/analytics/types";
import {
  SchoolQuoteFormType,
  SchoolQuoteSources
} from "features/teachers/pages/what-is-premium/analytics/consts";

import { SUBSCRIPTION_JOURNEY_SOURCES } from "../features/subscriptions/analytics/consts";
import { SpeedyMarkingMode } from "../features/teachers/pages/assignment/components/SpeedyMarking/SpeedyMarkingSession";
import { PreselectClassesOption } from "../features/teachers/types";

export const locationType: StateParamType<Location, PartialLocation> = {
  getPlainStateParam(value: PartialLocation) {
    return value;
  },
  getTypedStateParam(value) {
    return value as Location;
  }
};

export const anyValidator: Validator<any> = (value: unknown): any => {
  return value as any;
};

export const toValidator: Validator<To> = (value: unknown): To => {
  return value as To;
};

const schoolQuoteSourcesValidator: Validator<SchoolQuoteSources> = (
  value: unknown
): SchoolQuoteSources => {
  return value as SchoolQuoteSources;
};

export const schoolQuoteSourcesType = type(
  schoolQuoteSourcesValidator,
  parser("string")
);

const schoolQuoteFormTypeValidator: Validator<SchoolQuoteFormType> = (
  value: unknown
): SchoolQuoteFormType => {
  return value as SchoolQuoteFormType;
};

export const schoolQuoteFormTypeType = type(
  schoolQuoteFormTypeValidator,
  parser("string")
);

const subscriptionJourneySourcesValidator: Validator<
  keyof typeof SUBSCRIPTION_JOURNEY_SOURCES
> = (value: unknown): keyof typeof SUBSCRIPTION_JOURNEY_SOURCES => {
  return value as keyof typeof SUBSCRIPTION_JOURNEY_SOURCES;
};

export const subscriptionJourneySourcesType = type(
  subscriptionJourneySourcesValidator
);

const createAssignmentSourceValidator: Validator<CreateAssignmentSource> = (
  value: unknown
): CreateAssignmentSource => {
  return value as CreateAssignmentSource;
};

export const createAssignmentSourceType = type(createAssignmentSourceValidator);

const courseAddedLocationValidator: Validator<CourseAddedLocation> = (
  value: unknown
): CourseAddedLocation => {
  return value as CourseAddedLocation;
};

export const courseAddedLocationType = type(
  courseAddedLocationValidator,
  parser("string")
);

const preselectClassesOptionValidator: Validator<PreselectClassesOption> = (
  value: unknown
): PreselectClassesOption => {
  return value as PreselectClassesOption;
};

export const preselectClassesOptionType = type(
  preselectClassesOptionValidator,
  parser("string")
);

export const parentPlatformPreviewSourceType: StateParamType<ParentPlatformPreviewSource> =
  {
    getPlainStateParam(value: ParentPlatformPreviewSource) {
      return value;
    },
    getTypedStateParam(value) {
      return value as unknown as ParentPlatformPreviewSource;
    }
  };

const linkPropsTypeValidator: Validator<LinkProps> = (
  value: unknown
): LinkProps => {
  return value as LinkProps;
};

export const linkPropsType = type(linkPropsTypeValidator);

export const assignmentsTableModeType: StateParamType<AssignmentsTableMode> = {
  getPlainStateParam(value: AssignmentsTableMode) {
    return value;
  },
  getTypedStateParam(value) {
    return value as unknown as AssignmentsTableMode;
  }
};

export const selectedQuizzesValidator: Validator<SelectedQuiz[]> = (
  value: unknown
): SelectedQuiz[] => {
  return value as SelectedQuiz[];
};

export const selectedQuizzesType = type(selectedQuizzesValidator);

export const selectedCourseContentTypeValidator: Validator<
  SelectedCourseContent
> = (value: unknown): SelectedCourseContent => {
  return value as SelectedCourseContent;
};

export const selectedCourseContentType = type(
  selectedCourseContentTypeValidator
);

export const numberArrayValidator: Validator<number[]> = (
  value: unknown
): number[] => {
  return value as number[];
};

export const numberArrayType = type(numberArrayValidator);

export const speedyMarkingModeValidator: Validator<SpeedyMarkingMode> = (
  value: unknown
): SpeedyMarkingMode => {
  return value as SpeedyMarkingMode;
};

export const speedyMarkingModeType = type(
  speedyMarkingModeValidator,
  parser("string")
);

const tutoringFormModalStepValidator: Validator<TutoringFormModalStep> = (
  value: unknown
): TutoringFormModalStep => {
  return value as TutoringFormModalStep;
};

export const tutoringFormModalStepType = type(
  tutoringFormModalStepValidator,
  parser("string")
);

const oneToOneTutoringSourceValidator: Validator<TUTORING_LEAD_SOURCES> = (
  value: unknown
): TUTORING_LEAD_SOURCES => {
  return value as TUTORING_LEAD_SOURCES;
};

export const oneToOneTutoringSourceType = type(
  oneToOneTutoringSourceValidator,
  parser("string")
);

const shareSourceValidator: Validator<ShareSource> = (
  value: unknown
): ShareSource => {
  return value as ShareSource;
};

export const shareSourceType = type(shareSourceValidator, parser("string"));

const parentAutoInvitesFlowValidator: Validator<ParentAutoInvitesFlow> = (
  value: unknown
): ParentAutoInvitesFlow => {
  return value as ParentAutoInvitesFlow;
};

export const parentAutoInvitesFlowType = type(
  parentAutoInvitesFlowValidator,
  parser("string")
);

const MiniMockSessionDataValidator: Validator<MiniMockSessionData> = (
  value: unknown
): MiniMockSessionData => {
  return value as MiniMockSessionData;
};

export const MiniMockSessionDataType = type(MiniMockSessionDataValidator);

const AnyQuestionArrayValidator: Validator<Array<AnyQuestionI>> = (
  value: unknown
) => {
  return value as Array<AnyQuestionI>;
};

export const AnyQuestionArrayType = type(AnyQuestionArrayValidator);

const AnyAnswerArrayValidator: Validator<Array<AnyAnswerI>> = (
  value: unknown
) => {
  return value as Array<AnyAnswerI>;
};

export const AnyAnswerArrayType = type(AnyAnswerArrayValidator);

const courseSubscriptionTiersValidator: Validator<COURSE_SUBSCRIPTION_TIERS> = (
  value: unknown
): COURSE_SUBSCRIPTION_TIERS => {
  return value as COURSE_SUBSCRIPTION_TIERS;
};

export const courseSubscriptionTiersType = type(
  courseSubscriptionTiersValidator,
  parser("string")
);

const sectionContentTypeValidator: Validator<ContentType> = (
  value: unknown
): ContentType => {
  return value as ContentType;
};

export const sectionContentTypeType = type(
  sectionContentTypeValidator,
  parser("string")
);
