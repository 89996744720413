import { Location } from "history";

import { Link } from "common/types/routing";

import { getValueFromLinkState } from "seneca-common/utils/routing";

import {
  makePasswordResetPath,
  makeSignInPath,
  makeSignUpAccountTypePath,
  makeSignUpPath,
  makeSignUpStudentDetailsPath,
  makeSignUpTeacherDetailsPath
} from "./paths";

export type Options = {
  backLocation?: string;
  redirectAuthenticatedUserToLocation?: Link;
};

type MakeLink = (basePath?: string | Location, options?: Options) => Location;

export const makeLinkToSignIn: MakeLink = makeLinkWithState(makeSignInPath);

export const makeLinkToPasswordReset: MakeLink = makeLinkWithState(
  makePasswordResetPath
);

export const makeLinkToSignUp: MakeLink = makeLinkWithState(makeSignUpPath);

export const makeLinkToSignUpAccountType: MakeLink = makeLinkWithState(
  makeSignUpAccountTypePath
);

export const makeLinkToSignUpStudentDetails: MakeLink = makeLinkWithState(
  makeSignUpStudentDetailsPath
);

export const makeLinkToSignUpTeacherDetails: MakeLink = makeLinkWithState(
  makeSignUpTeacherDetailsPath
);

type MakePath = (basePath?: string) => string;

export function makeLinkWithState(makePath: MakePath): MakeLink {
  return function linkWithState(
    basePath?: string | Location,
    { backLocation, redirectAuthenticatedUserToLocation }: Options = {}
  ) {
    const location =
      typeof basePath === "string"
        ? {
            pathname: basePath
          }
        : basePath;
    const { pathname, state, ...other } = location || ({} as any);
    const locationState = state || {};

    return {
      pathname: makePath(pathname),
      state: {
        ...locationState,
        backLocation: backLocation || locationState.backLocation,
        redirectAuthenticatedUserToLocation:
          redirectAuthenticatedUserToLocation ||
          locationState.redirectAuthenticatedUserToLocation
      },
      ...other
    } as Location;
  };
}

// ### Link state utils ###

export const getBackLocation = (location: Location) =>
  getValueFromLinkState(location, "backLocation");

export const getRedirectAuthenticatedUserToLocation = (location: Location) =>
  getValueFromLinkState<Location | string>(
    location,
    "redirectAuthenticatedUserToLocation"
  );
